import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "../../components/layouts/layout"
import HeadMeta from "../../components/layouts/headmeta"
import ProgressBar from "../../components/layouts/progressbar"
import Reviews from "../../components/reviews"
import Faq from "../../components/faq"
import { changeProgressBarOnScroll } from "../../components/utils/index"
import CTA from "../../components/cta"
import AddToCart from "../../components/carts/AddToCart"

export default class DNAExplorerPersonalPage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", function() {
      changeProgressBarOnScroll()
    })
    window.analytics.page("Sales", "DNA Explorer Personal")
  }

  render() {
    const { faqs } = pageData
    const settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      autoplaySpeed: 5000,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    }
    return (
      <Layout>
        <div className="product-page" id="dna-explorer-personal">
          <ProgressBar />
          <HeadMeta metadata={pageData.metadata} />
          <section
            className="hero hero-landing bg-gray"
            style={{
              maxHeight: "90vh",
              backgroundPosition: "center",
              backgroundImage: `url("/img/ala-carte/background.png")`,
            }}
          >
            <div className="container">
              <div className="row d-flex">
                <div className="text-center col-12">
                  <h1 className="font-weight-bold" style={{ color: "white" }}>
                    Unlock The Secret
                    <br />
                    Of Your Gene
                  </h1>
                  <h2>
                    <span className="badge badge-pill badge-light text-gray">
                      Starting from RM179
                    </span>
                  </h2>
                  <a
                    className="btn btn-explorer-blue btn-lg font-weight-bold mt-5"
                    href="https://app.advanxhealth.com/products/overview?product=37"
                  >
                    GET IT NOW
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section id="about" className="about">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <img
                    src="/img/product/newbox.png"
                    className="img-fluid"
                    alt="Product box"
                  />
                </div>
                <div className="col-md-7">
                  <p>
                    <img
                      className="img-fluid"
                      style={{ maxHeight: "75px" }}
                      src={withPrefix("img/logo/DNA-Explorer-Personal.png")}
                      alt="DNA Explorer Personal"
                    />
                  </p>
                  <h2>Customise Your DNA Reports</h2>
                  <p className="lead mt-3">
                    Your DNA holds the secrets to not just your appearance, but
                    also your fitness, health risks, inner potential, and many
                    more.
                    <br />
                    <br />
                    Make your pick from 8 different report categories and create
                    your own customised DNA report!
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section id="report-categories" className="bg-gray">
            <div className="container text-center">
              <div className="row my-4">
                <div className="col-md-10 offset-md-1">
                  <h2 className="text-center">Reports Categories</h2>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-3 p-4">
                  <Link to={withPrefix("/reports/nutrition")}>
                    <img
                      src="/img/ala-carte/nutrigenomics.png"
                      className="img-fluid"
                      alt="Nutrigen"
                    />
                  </Link>
                  <AddToCart
                    buttonName="Add to cart"
                    price="179"
                    id="35"
                    class="btn-explorer-blue mt-2"
                    categoryName="Nutrigenomics"
                  />
                </div>
                <div className="col-md-3 p-4">
                  <Link to={withPrefix("/reports/inner-potential")}>
                    <img
                      src="/img/ala-carte/inner-potential.png"
                      className="img-fluid"
                      alt="Inner Potential"
                    />
                  </Link>
                  <AddToCart
                    buttonName="Add to cart"
                    price="179"
                    id="33"
                    class="btn-explorer-blue mt-2"
                    categoryName="Inner Potential"
                  />
                </div>
                <div className="col-md-3 p-4">
                  <Link to={withPrefix("/reports/allergy-sensitivity")}>
                    <img
                      src="/img/ala-carte/allergy.png"
                      className="img-fluid"
                      alt="Allergy & Sensitivity"
                    />
                  </Link>
                  <AddToCart
                    buttonName="Add to cart"
                    price="249"
                    id="34"
                    class="btn-explorer-blue mt-2"
                    categoryName="Allergy & Sensitivity"
                  />
                </div>
                <div className="col-md-3 p-4">
                  <Link to={withPrefix("/reports/weight-management")}>
                    <img
                      src="/img/ala-carte/weight-management.png"
                      className="img-fluid"
                      alt="Weight Management"
                    />
                  </Link>
                  <AddToCart
                    buttonName="Add to cart"
                    price="249"
                    id="36"
                    class="btn-explorer-blue mt-2"
                    categoryName="Weight Management"
                  />
                </div>
                <div className="col-md-3 p-4">
                  <Link to={withPrefix("/reports/skin")}>
                    <img
                      src="/img/ala-carte/skin.png"
                      className="img-fluid"
                      alt="Skin"
                    />
                  </Link>
                  <AddToCart
                    buttonName="Add to cart"
                    price="249"
                    id="37"
                    class="btn-explorer-blue mt-2"
                    categoryName="Skin"
                  />
                </div>
                <div className="col-md-3 p-4">
                  <Link to={withPrefix("/reports/fitness")}>
                    <img
                      src="/img/ala-carte/fitness.png"
                      className="img-fluid"
                      alt="Fitness"
                    />
                  </Link>
                  <AddToCart
                    buttonName="Add to cart"
                    price="349"
                    id="39"
                    class="btn-explorer-blue mt-2"
                    categoryName="Fitness"
                  />
                </div>
                <div className="col-md-3 p-4">
                  <Link to={withPrefix("/reports/health")}>
                    <img
                      src="/img/ala-carte/health-risk.png"
                      className="img-fluid"
                      alt="Health Risk"
                    />
                  </Link>
                  <AddToCart
                    buttonName="Add to cart"
                    price="349"
                    id="38"
                    class="btn-explorer-blue mt-2"
                    categoryName="Health Risks"
                  />
                </div>
                <div className="col-md-3 p-4">
                  <Link to={withPrefix("/products/dna-explorer-carrier")}>
                    <img
                      src="/img/ala-carte/carrier.png"
                      className="img-fluid"
                      alt="Carrier"
                    />
                  </Link>
                  <AddToCart
                    buttonName="Add to cart"
                    price="1499"
                    id="43"
                    class="btn-explorer-blue mt-2"
                    categoryName="Carrier"
                  />
                </div>
                <div className="col-md-3 p-4">
                  <Link to={withPrefix("/products/dna-explorer-onco")}>
                    <img
                      src="/img/ala-carte/cancer.png"
                      className="img-fluid"
                      alt="Carrier"
                    />
                  </Link>
                  <AddToCart
                    buttonName="Add to cart"
                    price="1390"
                    id="52"
                    class="btn-explorer-blue mt-2"
                    categoryName="Cancer"
                  />
                </div>
              </div>
              <div className="mb-4 w-100 text-center">
                <p>
                  *First-time customers are subject to a one-time lab handling
                  fee of RM400
                </p>
                {/* <a
                  className="btn btn-primary btn-lg font-weight-bold mt-5"
                  href="https://app.advanxhealth.com/products/overview?product=37"
                >
                  BUY NOW
                </a> */}
              </div>
            </div>
          </section>
          <section className="how-it-works container">
            <div className="row">
              <div className="col-12 text-center">
                <h2>How It Works</h2>
                <div className="mt-5 col-12">
                  <p>
                    <span className="btn btn-pink">NEW CUSTOMER</span>
                  </p>
                </div>
                <div className="row">
                  <div className=" col-6 col-md-3 p-1 p-md-3">
                    <img
                      src={withPrefix("img/ala-carte/how-it-works-new-1.png")}
                      className="img-fluid"
                      alt="how it works"
                    />
                  </div>
                  <div className="col-6 col-md-3 p-1 p-md-3">
                    <img
                      src={withPrefix("img/ala-carte/how-it-works-new-2.png")}
                      className="img-fluid"
                      alt="how it works"
                    />
                  </div>
                  <div className="col-6 col-md-3 p-1 p-md-3">
                    <img
                      src={withPrefix("img/ala-carte/how-it-works-new-3.png")}
                      className="img-fluid"
                      alt="how it works"
                    />
                  </div>
                  <div className="col-6 col-md-3 p-1 p-md-3">
                    <img
                      src={withPrefix("img/ala-carte/how-it-works-new-4.png")}
                      className="img-fluid"
                      alt="how it works"
                    />
                  </div>
                </div>
                <div className="mt-5 col-md-6 offset-md-3">
                  <p>
                    <span className="btn btn-pink">EXISTING CUSTOMER</span>
                  </p>
                </div>
                <div className="row">
                  <div className="col-6 col-md-3 offset-md-3 p-1 p-md-3">
                    <img
                      src={withPrefix(
                        "img/ala-carte/how-it-works-existing-1.png"
                      )}
                      className="img-fluid"
                      alt="how it works"
                    />
                  </div>
                  <div className="col-6 col-md-3 p-1 p-md-3">
                    <img
                      src={withPrefix(
                        "img/ala-carte/how-it-works-existing-2.png"
                      )}
                      className="img-fluid"
                      alt="how it works"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-gradient-pink-orange">
            <Reviews />
          </section>
          <section className="p-0 p-md-5">
            <div
              className="container bg-explorer-blue py-5 my-0"
              style={{ borderRadius: ".5rem" }}
            >
              <div className="row text-white align-items-center">
                <div className="col-md-1 offset-md-2">
                  <span className="text-left">
                    <i
                      className="fa fa-quote-left mb-4"
                      style={{ fontSize: "2.5rem" }}
                    ></i>
                  </span>
                </div>
                <div className="col-md-6">
                  <h3 className="text-white text-center ">
                    Your DNA loads the gun,
                    <br className="" /> your lifestyle pulls the trigger.
                  </h3>
                </div>
                <div className="col-md-1 text-right text-md-left">
                  <span className="">
                    <i
                      className="fa fa-quote-right mb-4"
                      style={{ fontSize: "2.5rem" }}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-0 bg-light" id="what-you-get">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5 order-2 order-md-1 text-center text-md-center">
                  <img
                    className="img-fluid"
                    src={withPrefix("img/logo/DNA-Explorer-Personal.png")}
                    alt="DNA Explorer Personal"
                  />
                  <h4>
                    Customise your report,
                    <br /> unlock the secrets to your DNA.
                  </h4>
                  <a
                    href="https://app.advanxhealth.com/products/overview?product=37"
                    className="btn btn-explorer-blue mt-4 btn-lg"
                  >
                    Get It Now
                  </a>
                </div>
                <div className="col-md-6 order-1 order-md-2">
                  <img
                    className="d-md-block product-gif"
                    src={withPrefix("/img/gifs/new-dashboard.gif")}
                    alt="Sample report"
                  />
                </div>
              </div>
            </div>
          </section>
          <section
            id="cta"
            className="cta"
            style={{ backgroundColor: "#FAFAFA" }}
          >
            <div className="container">
              <div className="row align-items-center my-5">
                <div className="col-md-10 offset-md-1 text-center">
                  <h2 className="text-center">Packages For You:</h2>
                  <p>
                    Don't know which reports to go for? Consider these packages
                    at value prices.
                  </p>
                </div>
              </div>
              <CTA />
            </div>
          </section>
          <section id="faq" className="py-5">
            <Faq />
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageData = {
  metadata: {
    title: "DNA Explorer Personal | Advanx Health",
    description:
      "Customise your own DNA report today starting from RM179 only! Discover your genetic predisposition in health risks, fitness potential, nutrition requirement, carrier status, and more.",
    image: "https://www.advanxhealth.com/img/ala-carte/banner.png",
    url: "https://www.advanxhealth.com/products/dna-explorer-personal",
  },
}
